import { Button, CircularProgress, Dialog, DialogContent, Paper, Stack, Typography } from "@mui/material";
import { dashboardSettings, uploadContainerSettings } from "../utils/dashboardSettingsConstants";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { MenuContext } from "../App";
import useUploadFile, { UploadError } from "../hooks/useUploadFile";
import { ErrorDialog } from "../components/ErrorDialog";

const ErrorContext = createContext<UploadError | null>(null);

export const Upload = () => {
    const { t } = useTranslation();
    const [file, setFile] = useState<null | File>(null);
    const fileRef = useRef<HTMLInputElement>(null);

    const { setSelectedMenu } = useContext(MenuContext);

    const { upload, loading, error, reset } = useUploadFile();

    useEffect(() => {
        setSelectedMenu("upload");
    }, [setSelectedMenu]);

    // if there is an error reset uploaded file
    useEffect(() => {
        setFile(null);
        if (fileRef && fileRef.current) {
            fileRef.current.value = "";
        }
    }, [error]);

    const handleClose = (event: {}, reason: string) => {
        if (reason && reason === "backdropClick") return;
    };

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFile(e.target.files?.item(0) || null);
    };

    return (
        <ErrorContext.Provider value={error}>
            <Stack width={dashboardSettings.containerWidth} m="auto" spacing={2} mt={5}>
                <Dialog open={loading} onClose={(event, reason) => handleClose(event, reason)}>
                    <DialogContent sx={{ margin: "auto" }}>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
                <Paper sx={uploadContainerSettings.style}>
                    <Stack p={3} spacing={2}>
                        <Typography fontSize="32px" fontWeight={500}>
                            {t("upload.input")}
                        </Typography>
                        <Typography fontSize="12px">{t("upload.description")}</Typography>
                        <Stack spacing={2}>
                            <input onChange={handleFileSelected} type="file" ref={fileRef} />
                            <Stack direction="row">
                                <Button
                                    disabled={file === null}
                                    variant="contained"
                                    size="small"
                                    sx={{ width: "98px" }}
                                    onClick={() => {
                                        if (file !== null) {
                                            const formData = new FormData();
                                            formData.append("file", file);
                                            upload(formData);
                                        }
                                    }}
                                >
                                    {t("upload.button")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
                <Paper sx={{ width: "320px", p: 3 }}>
                    <Stack spacing={2}>
                        <Typography fontSize="12px">{t("downloadFile.description")}</Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<FileDownloadIcon />}
                            href={
                                "https://www.jointimpactmodel.org/_files/ugd/1b74f3_aa9113de8e90414aa161d92dd7d58cc5.xlsx?dn=JIM%20Input%20Template%20-%20JIM%203.1.xlsx"
                            }
                        >
                            {t("downloadFile.button")}
                        </Button>
                    </Stack>
                </Paper>
            </Stack>
            <ErrorDialog error={error} reset={reset} />
        </ErrorContext.Provider>
    );
};
